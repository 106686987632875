import React, { useState, useEffect } from "react";

import Remember from "../component/Remember.jsx";

const RememberFeed = () => {
  const [remember, setRemember] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_PATH + "/remember", {
      method: "GET",
    })
      .then((response) => {
        // Check if the response was successful
        if (response.ok) {
          // Parse the response data as JSON
          return response.json();
        } else {
          throw new Error("Error retrieving memories");
        }
      })
      .then((data) => {
        // Access the retrieved data
        setRemember(data.data);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  }, []);
  return (
    <div id="rememberFeed">
      {remember.map((key) => {
        return <Remember key={key} memory={key} />;
      })}
    </div>
  );
};

export default RememberFeed;
