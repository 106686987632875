import React from "react";

const Album = (props) => {
  const randomHue = Math.floor(Math.random() * 361);

  return (
    <div
      onClick={props.onClick}
      className="album album-gradient"
      style={{
        "--randomHue": randomHue,
        "--saturation": "70%", // Adjust the saturation value for a pastel effect (e.g., 70%)
        "--lightness": "80%", // Adjust the lightness value for a lighter effect (e.g., 80%)
      }}
    >
      {props.albumName}
    </div>
  );
};

export default Album;
