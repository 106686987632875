import React from "react";
import "../style.scss";

const Remember = (props) => {
  const randomRotation = () => Math.floor(Math.random() * 4 - 2);
  const randomHue = Math.floor(Math.random() * 360);

  return (
    <div className="remember" style={{ "--rotation": randomRotation(), "--hue": randomHue }}>
      <div style={{ marginTop: "20px" }}>
        <h4>Remember When...</h4>
        <p>{props.memory}</p>
      </div>
    </div>
  );
};

export default Remember;
