import React, { useState, useEffect } from "react";
import ImageUpload from "../component/ImageUpload.jsx";

const Admin = () => {
  const [show, setShow] = useState(false);
  const [albums, setAlbums] = useState([]);
  const [remember, setRemember] = useState("");
  const [quote, setQuote] = useState("");
  const [quoteBy, setQuoteBy] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const closeModal = () => {
    setShow(false);
  };

  useEffect(() => {
    fetch(process.env.REACT_APP_API_PATH + "/getAlbumNames", {
      method: "GET",
    })
      .then((response) => {
        // Check if the response was successful
        if (response.ok) {
          // Parse the response data as JSON
          return response.json();
        } else {
          throw new Error("Error retrieving memories");
        }
      })
      .then((data) => {
        // Access the retrieved data
        setAlbums(data.folders);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  }, []);

  const handleLogin = () => {
    const formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);

    fetch(process.env.REACT_APP_API_PATH + "/verify", {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.message === "Login successful") {
          setLoggedIn(true);
        } else {
          alert("who tf are you homie");
        }
      })
      .catch((error) => {
        // Handle the error
        // For example, show an error message or perform error-specific actions
        console.log(error);
      });
  };
  if (!loggedIn) {
    return (
      <div id="adminPage">
        <div style={{ width: "100vw", textAlign: "center" }}>
          <h4>
            "If you are not one of the original flint fuckers, you have successfully infiltrated this website. Austin
            was supposed to take care of security so blame him"
            <br />
            <br />
            This page needs more work....visually....
          </h4>
        </div>
        <hr />
        <h6>Login</h6>
        <input type="text" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
        <br />
        <input type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        <br />
        <button onClick={handleLogin}>Login</button>
      </div>
    );
  }

  return (
    <div id="adminPage">
      <ImageUpload show={show} onClose={closeModal} albums={albums} />
      <div style={{ width: "100vw", textAlign: "center" }}>
        <h4>
          "If you are not one of the original flint fuckers, you have successfully infiltrated this website. Austin was
          supposed to take care of security so blame him"
          <br />
          <br />
          This page needs more work....visually....
        </h4>
      </div>
      <hr />
      <h6>Upload an image</h6>
      <button
        className="button"
        onClick={() => {
          setShow(true);
        }}
      >
        Upload an image
      </button>
      <br />
      <hr />
      <h6>Remember when...</h6>
      <input
        type="text"
        id="rememberWhen"
        onChange={(e) => {
          setRemember(e.target.value);
        }}
      />
      <button
        onClick={() => {
          const formData = new FormData();
          formData.append("memory", remember);
          fetch(process.env.REACT_APP_API_PATH + "/rememberWhen", {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              if (response.ok) {
                document.getElementById("rememberWhen").value = "";
              }
            })
            .catch((error) => {
              // Handle the error
              // For example, show an error message or perform error-specific actions
              console.log(error);
            });
        }}
      >
        Submit
      </button>
      <br />
      <hr />
      <h6>When someone said something funny</h6>
      <textarea
        type="text"
        id="quote"
        placeholder="Quote"
        onChange={(e) => {
          setQuote(e.target.value);
        }}
      />
      <br />
      <input
        type="text"
        id="quoteFrom"
        placeholder=" --by"
        onChange={(e) => {
          setQuoteBy(e.target.value);
        }}
      />
      <br />
      <button
        onClick={() => {
          const formData = new FormData();
          formData.append("quote", quote);
          formData.append("quoteBy", quoteBy);
          fetch(process.env.REACT_APP_API_PATH + "/rememberQuote", {
            method: "POST",
            body: formData,
          })
            .then((response) => {
              if (response.ok) {
                // Reset the input fields after successful submission
                document.getElementById("quoteFrom").value = "";
                document.getElementById("quote").value = "";
              }
            })
            .catch((error) => {
              // Handle the error
              // For example, show an error message or perform error-specific actions
              console.log(error);
            });
        }}
      >
        Submit
      </button>
      <br />
      <hr />
    </div>
  );
};

export default Admin;
