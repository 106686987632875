import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Views
import Landing from "./Views/Landing.jsx";
import Memories from "./Views/Memories.jsx";
import RememberFeed from "./Views/RememberFeed.jsx";
import Admin from "./Views/Admin.jsx";
import Quotes from "./Views/Quotes.jsx";

import HomeNav from "./component/HomeNav.jsx";

const App = () => {
  return (
    <>
      <Router>
        <HomeNav />
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/memories" element={<Memories />} />
          <Route path="/remember" element={<RememberFeed />} />
          <Route path="/a8min" element={<Admin />} />
          <Route path="/quote" element={<Quotes />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
