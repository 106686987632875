/*
    rights reserved from mb-gallery
    author: Shadman Chowdhury
*/

import "../style.scss";

const Memory = (props) => {
  const image_url = props.image_url;
  const title = props.caption;

  const randomRotation = () => `${Math.floor(Math.random() * 8 - 4)}deg`;

  return (
    <div className="thumbnail" style={{ "--rotation": randomRotation() }}>
      <img style={{ display: "block" }} src={image_url} alt={title} onClick={props.onClick} />
    </div>
  );
};

export default Memory;
