import React, { useEffect, useState } from "react";
import Quote from "../component/Quote";

const Quotes = () => {
  const [quotes, setQuotes] = useState([]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_PATH + "/quotes", {
      method: "GET",
    })
      .then((response) => {
        // Check if the response was successful
        if (response.ok) {
          // Parse the response data as JSON
          return response.json();
        } else {
          throw new Error("Error retrieving quotes");
        }
      })
      .then((data) => {
        // Access the retrieved data and format it
        const formattedQuotes = data.data.map((quote) => ({
          quote: quote[0],
          quote_by: quote[1],
        }));
        // Update the state with the formatted quotes
        setQuotes(formattedQuotes);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  }, []);

  return (
    <div className="quotes-container">
      <div className="quotes-list">
        {quotes.map((quote, index) => (
          <Quote key={index} quote={quote.quote} quote_by={quote.quote_by} />
        ))}
      </div>
    </div>
  );
};

export default Quotes;
