import React from "react";

import "../style.scss";

const Quote = (props) => {
  return (
    <div className="quote-card">
      <div className="quote-text">"{props.quote}"</div>
      <div className="quote-author">- {props.quote_by}</div>
    </div>
  );
};

export default Quote;
