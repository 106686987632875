import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../style.scss";

const Landing = () => {
  let navigate = useNavigate();

  const lyrics = [
    "We were speeding together down the dark avenues",
    "But besides all the stardom, all we got was blues",
    "But through all the sorrow, we've been riding high",
    "And the truth of the matter is I never let you go, let you go",
    "We were scanning the cities, rocking to pay their dues",
    "But besides all the glamour, all we got was bruised",
    "But through all the sorrow, we've been riding high",
    "And the truth of the matter is I never let you go, let you go",
    "You go down just like Holy Mary",
    "Mary on a, Mary on a cross",
    "Not just another bloody Mary",
    "Mary on a, Mary on a cross",
    "If you choose to run away with me, I will tickle you internally",
    "And I see nothing wrong with that",
  ];

  const [selectedLyric, setSelectedLyric] = useState("");

  const pickRandomLyric = () => {
    const randomIndex = Math.floor(Math.random() * lyrics.length);
    const randomLyric = lyrics[randomIndex];
    setSelectedLyric(randomLyric);
  };

  useEffect(() => {
    pickRandomLyric();
    // eslint-disable-next-line
  }, []);

  return (
    <div id="landing">
      <h1 id="holyLyrics">"{selectedLyric}"</h1>
      <br />
      <button
        className="button"
        onClick={() => {
          navigate("/remember");
        }}
      >
        Remember When
      </button>
      <button
        className="button"
        onClick={() => {
          navigate("/memories");
        }}
      >
        Memories
      </button>
      <button
        className="button"
        onClick={() => {
          navigate("/quote");
        }}
      >
        Quotes
      </button>
      <br />
      <button
        className="button"
        onClick={() => {
          navigate("/a8min");
        }}
      >
        I am one of them
      </button>
      <br />
    </div>
  );
};

export default Landing;
