import React from "react";
import "../style.scss";

const ImageModal = (props) => {
  const closeModal = () => {
    // Call the close modal handler from the parent component
    props.onCloseModal();
  };

  return (
    <>
      {props.show && (
        <div id="imageModal">
          <img id="imageModalImage" src={props.image} alt={props.alt} />
          <span className="closeModal" onClick={closeModal}>
            &times;
          </span>
        </div>
      )}
      {props.show && <div id="imageModalBackgroundOverlay" onClick={closeModal}></div>}
    </>
  );
};

export default ImageModal;
