import { useNavigate } from "react-router-dom";

import { MdOutlineHomeWork } from "react-icons/md";
import "../style.scss";

const HomeNav = () => {
  let navigate = useNavigate();
  return (
    <div id="homeNav">
      <span id="homeNavIcon">
        <MdOutlineHomeWork
          style={{ fontSize: "30px" }}
          onClick={() => {
            navigate("/");
          }}
        />
      </span>
    </div>
  );
};

export default HomeNav;
