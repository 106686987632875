import React, { useState, useEffect } from "react";
import "../style.scss";

import ImageModal from "../component/ImageModal.jsx";
import Memory from "../component/Memory.jsx";
import Album from "../component/Album.jsx";

const Memories = () => {
  const [memories, setMemories] = useState([]);
  const [imageModalDisplay, setImageModalDisplay] = useState(false);
  const [imageModalSrc, setImageModalSrc] = useState("");
  const [albums, setAlbums] = useState([]);

  const closeImageModal = () => {
    setImageModalDisplay(false);
  };

  useEffect(() => {
    //Fetch the albums
    fetch(process.env.REACT_APP_API_PATH + "/getAlbumNames", {
      method: "GET",
    })
      .then((response) => {
        // Check if the response was successful
        if (response.ok) {
          // Parse the response data as JSON
          return response.json();
        } else {
          throw new Error("Error retrieving memories");
        }
      })
      .then((data) => {
        // Access the retrieved data
        setAlbums(data.folders);
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });

    // Fetch the images
    fetch(process.env.REACT_APP_API_PATH + "/memories", {
      method: "GET",
    })
      .then((response) => {
        // Check if the response was successful
        if (response.ok) {
          // Parse the response data as JSON
          return response.json();
        } else {
          throw new Error("Error retrieving memories");
        }
      })
      .then((data) => {
        // Access the retrieved data
        for (let obj of data) {
          setMemories((prev) => [...prev, obj.image_url]);
        }
      })
      .catch((error) => {
        // Handle the error
        console.error(error);
      });
  }, []);

  return (
    <div id="imgGridHolder">
      <ImageModal show={imageModalDisplay} image={imageModalSrc} alt={imageModalSrc} onCloseModal={closeImageModal} />

      <h2>Albums</h2>

      <div id="albumContainer">
        {albums.map((name) => (
          <Album
            key={name}
            albumName={name.replace(/\/$/, "")}
            onClick={() => {
              //Get the album pictures
              fetch(process.env.REACT_APP_API_PATH + `/album/${name.replace(/\/$/, "")}`, {
                method: "GET",
              })
                .then((response) => {
                  // Check if the response was successful
                  if (response.ok) {
                    // Parse the response data as JSON
                    return response.json();
                  } else {
                    throw new Error("Error retrieving memories");
                  }
                })
                .then((data) => {
                  // Access the retrieved data
                  setMemories(data.images);
                })
                .catch((error) => {
                  // Handle the error
                  console.error(error);
                });
            }}
          />
        ))}
        <hr />
        <h4 style={{ fontFamily: "Cursive" }}>Memories Along The Way....</h4>
        <br />
      </div>
      <br />

      {memories.map((key) => (
        <Memory
          key={key}
          image_url={key}
          caption={""}
          author={""}
          onClick={(e) => {
            setImageModalDisplay(true);
            setImageModalSrc(e.target.currentSrc);
          }}
        />
      ))}
    </div>
  );
};

export default Memories;
