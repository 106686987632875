import React, { useState } from "react";
import "../style.scss";
import ImageModal from "./ImageModal"; // Import the ImageModal component

const ImageUpload = (props) => {
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [fileName, setFileName] = useState("");
  const [selectedAlbum, setSelectedAlbum] = useState("");
  const [newAlbum, setNewAlbum] = useState("");
  const [showNewAlbumInput, setShowNewAlbumInput] = useState(false);

  const closeModal = () => {
    props.onClose();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    if (file) {
      setSelectedFile(file);
    } else {
      setSelectedFile(undefined);
    }
  };

  const handleAlbumChange = (event) => {
    const album = event.target.value;
    setSelectedAlbum(album);
    if (album === "new") {
      setShowNewAlbumInput(true);
    } else {
      setShowNewAlbumInput(false);
    }
  };

  const handleNewAlbumChange = (event) => {
    setNewAlbum(event.target.value);
  };

  const handleSubmit = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("imageName", fileName);
      formData.append("image", selectedFile);

      let url = process.env.REACT_APP_API_PATH + "/upload";

      if (selectedAlbum) {
        if (selectedAlbum === "new" && newAlbum) {
          url += `/new-${encodeURIComponent(newAlbum)}`;
        } else {
          const sanitizedAlbum = selectedAlbum.replace(/\/$/, "");
          url += `/${encodeURIComponent(sanitizedAlbum)}`;
        }
      } else {
        url += "/memories";
      }

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          if (response.ok) {
            closeModal();
          }
          return response.json(); // Parse the response body as JSON
        })
        .then((data) => {
          // Access the parsed JSON data
          console.log(data);
        })
        .catch((error) => {
          // Handle the error
          // For example, show an error message or perform error-specific actions
          console.log(error);
        });
    }
  };

  return (
    <div id="image_uploadModal" className="image_modal" style={{ display: props.show ? "inline" : "none" }}>
      <div className="image_modal-content">
        <span className="image_close" onClick={closeModal}>
          &times;
        </span>
        <h2>Image Upload</h2>
        <input type="file" id="imageUploadInput" onChange={handleFileChange} />
        <br />
        <select value={selectedAlbum} onChange={handleAlbumChange}>
          <option value="">Select an album</option>
          <option value="new">New Album</option>
          {props.albums.map((album) => (
            <option key={album} value={album}>
              {album}
            </option>
          ))}
        </select>
        {showNewAlbumInput && (
          <input type="text" placeholder="Enter new album name" value={newAlbum} onChange={handleNewAlbumChange} />
        )}
        <br />
        <button id="uploadBtn" onClick={handleSubmit}>
          Upload
        </button>
      </div>

      {/* Pass the albums prop to the ImageModal component */}
      <ImageModal albums={props.albums} />
    </div>
  );
};

export default ImageUpload;
